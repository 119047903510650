import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Input from "../../components/input";
import axios from "@/axios";
import Loader from "@shared/loader";
import Dropdown from "@shared/components/dropdown-base";
import { checkColumnConfig, prePopulatedDropdownItems } from "@shared/utils/functions";

export default {
    name: "field-table",
    components: {
        SubHeader,
        "neo-button": Button,
        "neo-input": Input,
        Loader,
        Dropdown,
    },
    created() {
        return this.$route.path;
    },

    data() {
        return {
            columns: [
                {
                    label: "Group Name",
                    field: "title",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "name",
                    },
                },
                {
                    label: "No of users",
                    field: "user_count",
                    filterOptions: {
                        enabled: false, // enable filter for this column
                    },
                },
                {
                    label: "Group decription",
                    field: "description",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "description",

                    },
                },
            ],
            items: [],
            loading: false,
            showInputTypeModal: false,
            selectedField: null,
            groupname: "",
            clients: "",
            noOfUsers: "",
            groupdescription: "",
            active: true,
            client_id: null,
            searchTimeout: 500,
            selectedDropDownItems: null,
            searchParams: null,
            tableKey: 1
        };
    },
    async mounted() {
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        this.client_id = this.$store.getters.getClientId;
        this.items = await this.getGroups()
		this.columns =  prePopulatedDropdownItems(
			this.columns,
			this.items
		);
    },
    methods: {
        async addGroup() {
            try {
                let { data } = await axios.post("group/client", {
                    title: this.groupname,
                    description: this.groupdescription,
                    active: this.active,
                    client_id: this.client_id,
                });
                this.$toast.success(data.message || "Group created");
                this.items.push({
                    title: this.groupname,
                    description: this.groupdescription,
                    user_count: 0,
                    id: data.group_id,
                });
                this.hanldeCloseModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to delete group");
            }
        },
        hanldeCloseModal() {
            this.$modal.hide("group-modal");
        },
        handleShowModal() {
            this.selectedField = null;
            this.$modal.show("group-modal");
        },
        handleRowClick(data) {
            this.$router.push({
                name: "Group Setting",
                params: {
                    ...data,
                },
            });
        },
        async getGroups(){
			let items = []
			try {
				this.loading = true;
				let {data} = await axios.get(`group/client/${this.client_id}`, { params: { ...this.searchParams } });
				items =  data;
			} catch (error) {
				console.log("error", error);
			}
			this.loading = false;
			return items
		},

        // Filters
		checkColumnConfig,
        async updateColumnFilters(column, value /* , callback */) {
            let selected = {
                key: column.filterOptions.query_key || column.field,
                value: column.filterOptions.multi ? value.map((el) => el.name) : [value?.name],
            };
            if (!selected.value.length) {
                delete this.selectedDropDownItems[selected.key];
                return (this.items = await this.getGroups());
            }

            this.selectedDropDownItems = { ...this.selectedDropDownItems, [selected.key]: selected.value };
            let newLists = await this.filterListDropdownItem();
            this.items = newLists;
        },
        async filterListDropdownItem() {
            this.loading = true;
            let url = `group/client/groups`;
            let items = null;
            try {
                let body = {
                    client_id: this.client_id,
                    ...this.selectedDropDownItems,
                };
                let { data } = await axios.post(url, body);
				console.log(data, "Ass")
                items = data.data;
            } catch (error) {
                console.log(error, "error while fetching users");
            }
            this.loading = false;
            return items;
        },
        searchFilter(column, event) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(column, event);
            }, delay);
        },
        async getSearchOptions(column, event) {
            let search = {
                key: column.filterOptions.query_key || column.field,
                value: event,
            };
            this.searchParams = { ...this.searchParams, [search.key]: search.value };
            let filteredDrowDowns = await this.getGroups();
            this.columns = prePopulatedDropdownItems(this.columns, filteredDrowDowns);
        },
        async clearFilters(){
            this.tableKey+=1;
            this.selectedDropDownItems = {};
            this.$refs.clientTable.reset();
            this.items = await this.getGroups()
            this.columns =  prePopulatedDropdownItems(
                this.columns,
                this.items
            );
        },
    },
};
